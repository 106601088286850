import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '19, 74, 159',
		'primary-dark': '1, 5, 79',
		'accent': '254, 213, 48',
		'accent-plus': '0, 0, 0',
	},
});
